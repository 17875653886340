.container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

/* positioned in the "placeholder" state, in the 
   middle of the input */
.container .label {
  position: absolute;
  /* label can be clicked through */
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-size: 16px;
  line-height: 1;
  left: 10px;
  min-width: 0;
}

/* when the input is focused, do this to the label */
.container:focus-within .label {
  transform: translate(0, 12px) scale(0.8);
  color: #5e71c4;
  font-weight: bold;
}

.container .input {
  display: flex;
  padding: 24px 16px 8px;
  height: 60px;
  border-radius: 8px;
  min-width: 0;
  border-width: 0;
}

.input::placeholder {
  color: transparent;
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
  transform: translate(0, 12px) scale(0.8);
  left: 16px;
  /* font-weight: bold; */
}
